import React from 'react'
import styles from './cookiePolicy.module.scss'
import EmptyBlock from '../Header/emptyBlock'


export default class TermsOfUse extends React.Component {
  //   handleCloseFeatures = () => {
  //     // document.getElementById('drop-down').classList.remove('drop-down-active');
  //     document.getElementById('drop-down-pricing').classList.remove('drop-down-active');
  //     document.getElementById('overlay').removeAttribute('class');
  //     document.body.removeAttribute('class');
  // }
  render() {
    return (
      <>
        <EmptyBlock />
        {/* <div onMouseEnter={this.handleCloseFeatures} id='overlay'></div> */}
        <div className={styles.main}>
          <div className={styles.content}>
            <h1 className={styles.header}>Terms of Use</h1>
            <h2>Permit for Utilization of the Services</h2>
            <p>You don’t have a right to utilize The Services for leasing, offering, subleasing, redistributing and other business purposes. These Conditions and Terms direct any progressions or updates made to the first Administration discharged by DueFocus Beta and Diligences. By utilizing the Services, you consent to not take illicit activities and prohibited by these Terms.</p>
            <p>The DueFocus Trademarks are DueFocus logos, names, items and Services. You guarantee to not display or use them in any manner.</p>
          </div>
          <div className={styles.content}>
            <h2>Use Restrictions</h2>
            <p>We enable DueFocus Services to be used by individuals who are no less than 18 years of age. Clients who didn’t achieve the age of 18, are prohibited to buy, enlist, access, or utilize any of DueFocus Beta Services unless they are given supervision by their parents, guardians, or responsible adults.</p>
            <p>We depend on parents and guardians to guarantee that minors only utilize DueFocus items and Services provided that they can comprehend their rights and duties as expressed in this Terms of Use and our Protection Strategy under the supervision.</p>
          </div>
          <div className={styles.content}>
            <h2>Agree to Utilization of Information
              <br />
              Use Restrictions</h2>
            <p>You consent to enable DueFocus to collect and utilize information about Client Data as examined in its <span>Privacy Policy</span>. This incorporates however not limits to specialized data about software, operating system, and peripherals. We gather this information to simplify the procedure of updates and software customization.</p>
            <p>DueFocus can utilize the described information to update the Services and release new versions. We treat Personal Information uniquely in contrast to technical one and utilize it just to recognize, locate, or contact you.</p>
          </div>
          <div className={styles.content}>
            <h2>Rights and obligations</h2>
            <p>You can’t utilize our Services for getting access to information of the organization in which you are not a worker or remote representative (Freelancer).</p>
            <p>You can’t share one DueFocus account with many individuals. For every individual, you ought to make a different profile.</p>
            <p>You have the full responsibility for any actions performed in or with your account.</p>
            <p>You should not utilize the Services for any prohibited actions or illegal reasons (distribution of violent or pornographic information, stalking, spam etc). When utilizing the Services, you guarantee to not damage any laws in your jurisdiction, including copyright laws however not limited to them.</p>
            <p>You will keep DueFocus and its associates, specialists, and workers safe from and against all legal obligation, and cost (counting sensible lawyer’s charges) endured or acquired by reason of any cases, procedures or suits related to the utilization of the Services.</p>
            <p>You need to confirm your email address in 1 month, or we will erase your information and account.</p>
            <p>Any inquiries identified with the Terms and Conditions can be sent via email <span>support@diligences.com</span>.</p>
          </div>
          <div className={styles.content}>
            <h2>When utilizing the Services of Diligences and subsequently consenting to these Terms, you ensure </h2>
            <p>Not to make any illegal activities.</p>
            <p>Not to take part in any illegal action or the one that undermines to hurt youngsters.</p>
            <p>Not to spread spam. Spam is an undesired or unsolicited letter, contact requests, SMS (instant messages) etc.</p>
            <p>Not to utilize the Services to freely show or offer illegal material or content (for instance, the one that contains violence, nudity, pornography etc).</p>
            <p>Not to make misleading or false activities or solicitations (approaching users with financial request for no lawful reasons, upheld by appropriate proof etc).</p>
            <p>Not to imply that you possess any specific rights or accessibility of the Services</p>
            <p>Not to take part in the dangerous activity and the one that threatens you, Services, or other individuals, for example, transmitting or introducing malicious software, posting unlawful content, stalking, communicating in a violent or offensive manner and so forth.</p>
            <p>Not to abuse the privileges of others (unapproved sharing of intellectual property, for example, copyrighted music, pictures, or messages)..</p>
            <p>Not to urge other individuals to violate the Terms or help up doing as such.</p>
          </div>
          <div className={styles.content}>
            <h2>Utilizing Third-Party Applications and Services</h2>
            <p>The Services can enable you to utilize or reach different sites, software, services or applications from different organizations or third-party individuals (Third-Party Applications and Services).</p>
            <p>Some Services can enable the access to Third-Party Applications. While using them, you agree to direct our Services and Third-party products to you. The Third-Party Applications can enable you to store your content. You share the responsibility with a maker, administrator, and distributor of the Third-Party Applications.</p>
            <p>The Third-Party Applications or Services are permitted to show you their own terms and conditions. None of the extra Terms have any effect on these Terms, do not change or substitute them. If you are utilizing Third-Party Applications, you consent to their Terms of Use.</p>
            <p>You need to survey other Terms and Conditions offered by Third-Party Applications before utilizing them. Diligences does not take responsibility for Third-Party Applications or their Terms. While helping you locate a Third-Party Application, Diligences does not permit any content or licensed innovation to you. You assume full liability for any communications with Outsider Services.</p>
          </div>
          <div className={styles.content}>
            <h2>Updates to the Services or Programming, and Changes to These Terms</h2>
            <p>Once the Terms are altered, you will be informed about any updates. On the off chance that you keep utilizing the Services, it implies your consent to take after the modified Terms. You have a right to not concur with updates in which case you ought to erase your account and quit utilizing the Services.</p>
            <p>We have a privilege to update the Services. In order to successfully develop and implement updated or re-designed versions of the product, we can collect information about your application and operating system. Any updates will comply with these Terms unless there are changes made. In this case, the Services will subject to new Terms. Diligences does not ensure any updates will be introduced. Diligences likewise does not guarantee that our product must work on your current licensed version of operating system.</p>
            <p>We occasionally need to publish design changes or updates in beta-testing stage and improve them later. We additionally have a privilege to stop giving access to Third-Party Applications or Services. We have no obligation to give a substitution to any content, applications, or Digital Goods, aside from the cases discussed by applicable law.</p>
          </div>
        </div>
      </>
    )
  }
}
