import { Link } from "gatsby";
import React from "react";
import styles from "../Header/style.module.scss";

export default function EmptyBlock () {
    return (
        <div id="empty-block" className="empty-block-h" />
    )
  }




